import React, {useRef, useEffect, createRef} from "react";
import { Seo } from "../../components/Seo";
import {graphql} from 'gatsby';
import Carousel from "../../components/Projects/Carousel";
import { StaticImage } from "gatsby-plugin-image";
import ContactForm from "../../components/ContactForm";

const BachEyewearProject = ({data}) => {
  const textRef = useRef([]);
  const imageRef = useRef([]);
  for(let i = 0; i < 3; i++) {
    textRef.current[i] = createRef();
    imageRef.current[i] = createRef();
  }

  const resizeSection = () => {
    for(const[k,i] of Object.entries(textRef.current)) {
      if(i.current || false) {
        let mheight = i.current.offsetHeight + 40;
        imageRef.current[k].current.style.maxHeight = `${mheight}px`;
      }
    }
  }

  useEffect(() => {
    window.addEventListener('resize', resizeSection);
    resizeSection();
  }, []);

  const projects = data.allPrismicMixedContentProject.edges.map((i,k) => ({
    to: i.node.uid,
    name: i.node.data.title,
    description: i.node.data.description,
    src: i.node.data.cover_image.url
  }))
  return (
    <main>
      <section>
        <div className="grid lg:grid-cols-2 items-start">
          <div ref={textRef.current[0]}
            className="py-24 px-16 xl:px-32">
            <h1 className="font-black text-4xl lg:text-5xl mb-12">
              Bach Eyewear.<br />
              Where Fashion & Safety Meet.<br />
              Manufactured in China.<br />
            </h1>
            <p className="text-xl lg:text-2xl mb-8">
              Bach Eyewear set out to change the way the trade industry sees eyewear. The goal was to design and create a range of protective sunglasses that could be used in everyday life but that also met Australian AS/NZ Safety Certification making them safe for any tradie to wear on any job site. With Sourci’s help over 9 months of product development, sampling and production, we finally had the first drop of Bach Eyewear and they look incredible, see for yourself!
            </p>
            <p className="text-xl lg:text-2xl mb-8">
              Bach Eyewear Founder Simon, approached Sourci with his unique designs in early 2020, after months of designing and planning. We began research and quoting to ensure we found a manufacturing partner who understood the technical requirements combined with ultimate aesthetics. We began the process of tooling and sampling for the first round of prototypes swiftly.
            </p>
            <p className="text-xl lg:text-2xl mb-8">
              Several months later we had a finished product that Simon was beyond happy with. Sourci then organised independent testing of the glasses at a third party testing facility to ensure they complied with the required AUS/NZ Safety Standards.
            </p>
            <p className="font-bold text-xl lg:text-2xl">Here's how we made amazing happen for Bach Eyewear.</p>
          </div>
          <div className="flex h-full"
            ref={imageRef.current[0]}>
              <StaticImage src="../../images/bach-eyewear-1.jpg"
                alt=""
                className="object-cover min-w-[100%]" />
          </div>
        </div>
      </section>

      <section className="bg-[#f8f8f8] py-24">
        <div className="xl:container grid-cols-1 lg:grid-cols-4 mx-auto px-6 grid text-center gap-x-12 gap-y-12">
          <div>
            <p className="font-black text-5xl lg:text-6xl mb-3">
              <span>4</span>
            </p>
            <p className="font-black text-xl">Product Range</p>
          </div>
          <div>
            <p className="font-black text-5xl lg:text-6xl mb-3">
              <span>AUS/NZ</span>
            </p>
            <p className="font-black text-xl">SAFETY CERTIFICATION</p>
          </div>
          <div>
            <p className="font-black text-5xl lg:text-6xl mb-3">
              <span>9</span>
            </p>
            <p className="font-black text-xl">MONTHS SAVED</p>
          </div>
          <div>
            <p className="font-black text-5xl lg:text-6xl mb-3">
              <span>&#8593;$60,000</span>
            </p>
            <p className="font-black text-xl">OF SAVINGS</p>
          </div>
        </div>
      </section>

      <section>
        <div className="grid lg:grid-cols-2 items-start">
          <div className="flex h-full"
            ref={imageRef.current[1]}>
              <StaticImage src="../../images/bach-eyewear-3-2175w.jpg"
                alt=""
                className="object-cover min-w-[100%]" />
          </div>
          <div ref={textRef.current[1]}
            className="py-24 px-16 xl:px-32">
            <h3 className="font-black text-2xl lg:text-3xl mb-12">
            Fast-tracked Sampling & Production
            </h3>
            <p className="text-xl lg:text-2xl mb-8">
              Bach Eyewear's goal was to launch in early 2021, with Sourci’s team aligning with the perfect manufacturing partner, we estimate that we were able to save 4-6 months of development time by producing multiple components of the glasses at the same time through separate manufacturing partners, and consolidating them through our primary factory - this produced samples in half the time and saved 2 months of additional manufacturing time.
            </p>
            <p className="text-xl lg:text-2xl mb-8">
              With Simon's full focus now on marketing, he was able to create and execute an explosive launch campaign, and create magical momentum.
            </p>
            <p className="text-xl lg:text-2xl mb-8">
              Successful E-commerce founders are marketing and selling extraordinaires. They don't need to get bogged down dealing with product & supply chain - leave making amazing products to the experts and thrive with full focus on your growth.
            </p>
            <p className="text-xl lg:text-2xl mb-8">
              It's time to see the world through a beautiful new lens.
            </p>
            <p className="text-xl lg:text-2xl mb-8">
              Want a pair for yourself? <a href="https://www.bacheyewear.com/"
                className="text-sourci-teal hover:underline"
                target="_blank"
                rel="noreferrer">Visit their website here</a>.
            </p>
            <p className="font-bold text-xl lg:text-2xl">You're in charge here.</p>
          </div>
        </div>
      </section>

      <section className="bg-[#f8f8f8] py-24">
        <div className="xl:container mx-auto px-6 text-center">
          <h3 className="font-black text-5xl lg:text-6xl">
            Sourci puts product sourcing wizardry at the heart of your team. Giving total control and support, so those uncertainties just disappear.
            <span className="block text-sourci-pink">You got this.</span>
          </h3>
        </div>
      </section>

      <section className="bg-[#f8f8f8] py-32">
        <div className="xl:container lg:grid lg:grid-cols-2 mx-auto px-6">
          <div className="text-black">
            <p className="text-4xl md:text-6xl font-black mb-8">The time<br /> is now.</p>
            <p className="text-black text-4xl mb-8 font-black">Let's make amazing<br /> happen today.</p>
            <p className="text-3xl mb-2">
              <a href="tel:+611300768724"
                className="hover:underline">1300 SOURCI</a>
            </p>
            <p className="text-3xl mb-2">
              <a href="mailto:hello@sourci.com.au"
                className="hover:underline">hello@sourci.com.au</a>
            </p>
          </div>
          <div className="mt-12 lg:mt-0">
            <ContactForm bg="light"
              location="Case Study - Bach Eyewear" />
          </div>
        </div>
      </section>
      <Carousel projects={projects}
        project="bach-eyewear"
        title="Check out our other Projects" />
    </main>
  )
}

export const query = graphql`
  query {
    allPrismicMixedContentProject {
      edges {
        node {
          data {
            title
            description
            cover_image {
              url
            }
          }
          uid
        }
      }
    }
  }
` 

export default BachEyewearProject;

export function Head() {
  return (
    <Seo title="Bach Eyewear | Our Projects | Sourci"
      description="Bach Eyewear's goal was to launch in early 2021, with Sourci’s team aligning with the perfect manufacturing partner, we estimate that we were able to save 4-6 months of development time"
      canonical="/projects/bach-eyewear" />
  )
}